<template>
  <q-form @submit="onSubmit" ref="formRef" @reset="onReset" class="row">
    <Personal
      v-if="component_settings?.Personal?.enabled ?? true"
      :settings="component_settings?.Personal ?? {}"
    />
    <Adress
      v-if="component_settings?.Adress?.enabled ?? true"
      :settings="component_settings?.Adress ?? {}"
    />
    <Birthdate
      v-if="
        component_settings?.Birthdate?.enabled ?? view != 'retention' ?? true
      "
    />
    <Payment
      v-if="component_settings?.Payment?.enabled ?? true"
      :settings="component_settings?.Payment ?? {}"
    />
    <CurrentSituation
      v-if="component_settings?.CurrentSituation?.enabled ?? false"
      :settings="component_settings?.CurrentSituation ?? {}"
    />
    <Contact
      v-if="component_settings?.Contact?.enabled ?? true"
      :settings="component_settings?.Contact ?? {}"
    />
    <StepperNavigation
      class="col-12 row justify-end q-pb-md"
      @next-step="onSubmit"
      @previous-step="previousStep()"
      :loading="loading"
    />
  </q-form>
</template>

<script setup>
import { computed, ref } from "@vue/reactivity";
import { defineAsyncComponent, watch } from "vue";
import { useStore } from "vuex";

const Personal = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/Personal.vue")
);
const Adress = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/Adress.vue")
);
const Birthdate = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/Birthdate.vue")
);
const Payment = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/Payment.vue")
);
const Contact = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/Contact.vue")
);
const CurrentSituation = defineAsyncComponent(() =>
  import("@/components/ProspectInformation/CurrentSituation.vue")
);
const StepperNavigation = defineAsyncComponent(() =>
  import("@/components/StepperNavigation.vue")
);

//* Props
// eslint-disable-next-line no-undef
const props = defineProps({
  component_settings: Object,
  settings: Object,
});

//* VueUse
const $store = useStore();

//* Refs
const formRef = ref(null);
const submitCalled = ref(false);
const loading = ref(false);

//* Computed
const view = computed(() => $store.getters.view);
const viewMode = computed(() => $store.getters.viewMode);
const pendingRequests = computed(() => $store.getters.pendingRequests);
watch(pendingRequests, () => {
  if (
    submitCalled.value == true &&
    Object.entries($store.getters.poll).length == 0 &&
    pendingRequests.value <= 0
  ) {
    submitCalled.value = false;
    setTimeout(() => {
      onSubmit();
    }, 500);
  }
});

//* Methods
function onSubmit() {
  if (viewMode.value === true) {
    nextStep();
    return;
  }
  if (
    Object.entries($store.getters.poll).length != 0 ||
    pendingRequests.value > 0 ||
    submitCalled.value == true
  ) {
    submitCalled.value = true;
    loading.value = true;
    return;
  }
  formRef.value.validate().then((success) => {
    if (success) {
      let verify = "verifyAllOffers";

      if (view.value != "multioffer") {
        $store.dispatch("send_message", { message: "save_personal_details" });
        $store.dispatch("send_message", { message: "save_correspondence" });
        $store.dispatch("send_message", { message: "save_payment_details" });

        verify = "verifyOffer";
      }

      if (
        (props?.settings?.verify_offer && view.value != "energyportal") ||
        (!props?.settings?.verify_offer && view.value != "multioffer")
      ) {
        $store.dispatch(verify).then(() => {
          $store.dispatch("updateStatus", {
            status: 3,
          });
        });
      } else {
        loading.value = false;
        nextStep();
      }
    }
  });
}

function previousStep() {
  $store.dispatch("decrementCurrentStep");
}

function nextStep() {
  $store.dispatch("incrementCurrentStep");
}
</script>

<style lang="sass"></style>
